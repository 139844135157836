import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Modal, Button } from "react-bootstrap";
import AddPatient from "./forms/AddPatient";
import BookAppointment from "./forms/BookAppointment";

function Form() {
  return (
    <Wrapper>
      <div className="">
        <div className="row shadow p-1 border rounded bg-white" id="section3">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active fw-bold"
                id="home-tab1"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Add Patient
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link fw-bold"
                id="profile-tab1"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                Book Appointment
              </button>
            </li>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabindex="0"
              >
                <AddPatient />
              </div>

              <div
                className="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabindex="0"
              >
                <BookAppointment />
              </div>
            </div>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default Form;

const Wrapper = styled.div`
  position: relative;
  #section3 {
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
      width: 75%;

      margin: 1rem auto;
    }
    @media screen and (min-width: 770px) and (max-width: 1020px) {
      width: 75%;

      margin: 1rem auto;
    }
  }
  .formbtn {
    margin-top: 1rem;
  }

  #tab1 {
    height: 12rem;
    overflow-y: auto;
    @media screen and (max-width: 768px) {
      margin-left: -1.1rem;
    }
  }

  #btn2 {
    @media screen and (max-width: 768px) {
      font-size: small;
      margin-left: 5.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      font-size: small;
      margin-left: 1rem;
    }
    @media screen and (min-width: 1020px) and (max-width: 1600px) {
      margin-bottom: 1rem;
      font-size: small;
    }
    @media screen and (min-width: 1600px) and (max-width: 3700px) {
      margin-bottom: 1rem;
      font-size: small;
    }
  }
  #myTabContent {
    height: 28.5rem;
    overflow-y: auto;
  }
  .nav-link {
    @media screen and (min-width: 1020px) and (max-width: 1200px) {
      font-size: small;
    }
  }
`;

// const FormContainer = styled.div`
//   /* Your styling for the form container */
//   margin-top: 50px;
// `;

const PatientList = styled.div`
  position: absolute;

  z-index: 1000;
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
  /* Your styling for the patient list */
`;
const DoctorList = styled.div`
  position: absolute;
  z-index: 999; /* Set a high z-index to ensure the list is displayed above other elements */
  width: 100%;
  overflow-y: auto;
  max-height: 400px;

  /* Your additional styling for the doctor list */
`;
